import { debounce } from "../helper/functions";
import { ON_CHANGE_DEBOUNCE_TIMER } from "../helper/constants";
import { subscribe } from "../helper/pubsub";
import { PUB_SUB_EVENTS, nostojs } from '../helper/constants';
import { fetchConfig } from "../helper/fetch-config";
import { trapFocus } from "../helper/functions";
import { publish } from "../helper/pubsub";

class CartDrawer extends HTMLElement {
  constructor() {
    super();

    this._overlay = this.querySelector("#CartDrawer-Overlay");
    this.cart = document.querySelector('cart-drawer');
    this.items = this.querySelector('cart-drawer-items');
    //this.trigger(this)
    this.bindToggles();

    this.urlParams = new URLSearchParams(window.location.search);
    this.hasParam = this.urlParams.has("cart");

    if(this.hasParam){
      this.trigger(this);
    }
  }


  bindToggles() {
    this._triggers = document.querySelectorAll(`${this.getAttribute('trigger-element-selector')}`);
    this.querySelector('#CartDrawer-Overlay').addEventListener('click', this.close);
    this._triggers.forEach(trigger => {
      trigger.setAttribute('role', 'button');
      trigger.setAttribute('aria-haspopup', 'dialog');
      trigger.addEventListener('click', (event) => {
        event.preventDefault();
        this.trigger(this);
      });
    });
    this._overlay.addEventListener('click', (event) => {
      event.preventDefault();
      this.trigger(this);
    })

    document.body.addEventListener('docapp-discount-applied', (e) => {
      setTimeout(() => {
        fetch(`${routes.cart_url}?section_id=cart-drawer`)
      .then((response) => response.text())
      .then((responseText) => {
        const html = new DOMParser().parseFromString(responseText, 'text/html');
        const sourceItems = html.querySelector('cart-drawer-items');
        this.querySelector('.totals__subtotal-value').innerHTML = html.querySelector('.totals__subtotal-value').innerHTML
        this.items.innerHTML = sourceItems.innerHTML
      })
      .catch((e) => {
        console.error(e);
      });
      }, 200)
    });

  }

  isOpen(triggeredElement) {
    return triggeredElement.hasAttribute('open');
  }

  trigger(triggeredElement) {
    if(this.isOpen(triggeredElement)) {
        triggeredElement.removeAttribute('open');
        this.setAttribute('aria-hidden', 'true');
        this.classList.add('translate-x-full');
        document.body.classList.remove('overflow-hidden');
        //CLOSE ALL DROPDOWNS IN HEADER
        //document.querySelector('header-wrapper').scrollTopHandler();
    } else {
        triggeredElement.setAttribute('open', true);
        this.classList.remove('translate-x-full');
        this.setAttribute('aria-hidden', 'false');
        document.body.classList.add('overflow-hidden');
        //document.querySelector('header-wrapper').scrolledDownHandler();
    }
  }

  renderContents(parsedState) {
    console.log(parsedState, this.getSectionsToRender())
    this.querySelector('.drawer__inner').classList.contains('is-empty') &&
    this.querySelector('.drawer__inner').classList.remove('is-empty');
    this.productId = parsedState.id;
    this.getSectionsToRender().forEach((section) => {
      const sectionElement = section.selector
        ? document.querySelector(section.selector)
        : document.getElementById(section.id);
      sectionElement.innerHTML = this.getSectionInnerHTML(parsedState.sections[section.id], section.selector);
      const totalQuant = sectionElement.querySelector('.cart__count').textContent;
      const cartCount = document.querySelectorAll('.cart__count');
      cartCount.forEach((element) => {
        element.innerHTML = totalQuant;
      })
    });
  }

  getSectionInnerHTML(html, selector) {
    console.log(selector)
    return new DOMParser().parseFromString(html, 'text/html').querySelector(selector).innerHTML;
  }

  getSectionsToRender() {
    return [
      {
        id: 'cart-drawer',
        selector: '#CartDrawer',
      },
    ];
  }

  getSectionDOM(html, selector = '.shopify-section') {
    return new DOMParser().parseFromString(html, 'text/html').querySelector(selector);
  }

  setActiveElement(element) {
    this.activeElement = element;
  }
}

customElements.define('cart-drawer', CartDrawer);

class CartItems extends HTMLElement {
  constructor() {
    super();
    this.lineItemStatusElement =
      document.getElementById('shopping-cart-line-item-status') || document.getElementById('CartDrawer-LineItemStatus');

    const debouncedOnChange = debounce((event) => {
      this.onChange(event);
    }, ON_CHANGE_DEBOUNCE_TIMER);

    this.wrapper = this.querySelector('.drawer__cart-items-wrapper');
    this.footer = document.querySelector('.cart-drawer__footer');
    this.header = document.querySelector('.cart-drawer__header');

    this.addEventListener('change', debouncedOnChange.bind(this));

    //update height of cart item container
  }

  cartUpdateUnsubscriber = undefined;

  connectedCallback() {
    this.cartUpdateUnsubscriber = subscribe(PUB_SUB_EVENTS.cartUpdate, (event) => {
      if (event.source === 'cart-items') {
        return;
      }
      this.onCartUpdate();
    });



    const height = window.innerHeight;
    const headerHeight = this.header.clientHeight;
    const footerHeight = this.footer.clientHeight;

    this.parentElement.style.height = height - ( headerHeight + footerHeight ) + 'px';
    window.addEventListener('resize', e => {
      const height = window.innerHeight;
      const headerHeight = this.header.clientHeight;
      const footerHeight = this.footer.clientHeight;
      this.parentElement.style.height = height - ( headerHeight + footerHeight ) + 'px';
    })
  }

  disconnectedCallback() {
    if (this.cartUpdateUnsubscriber) {
      this.cartUpdateUnsubscriber();
    }
  }

  onChange(event) {
    this.updateQuantity(event.target.dataset.index, event.target.value, document.activeElement.getAttribute('name'));
  }

  onCartUpdate() {
    fetch(`${routes.cart_url}?section_id=main-cart-items`)
      .then((response) => response.text())
      .then((responseText) => {
        const html = new DOMParser().parseFromString(responseText, 'text/html');
        const sourceQty = html.querySelector('cart-items');
        this.innerHTML = sourceQty.innerHTML;
      })
      .catch((e) => {
        console.error(e);
      });
  }

  getSectionsToRender() {
    return [
      {
        id: 'main-cart-items',
        section: document.getElementById('main-cart-items').dataset.id,
        selector: '.js-contents',
      },
      {
        id: 'cart-icon-bubble',
        section: 'cart-icon-bubble',
        selector: '.shopify-section',
      },
      {
        id: 'cart-live-region-text',
        section: 'cart-live-region-text',
        selector: '.shopify-section',
      },
      {
        id: 'main-cart-footer',
        section: document.getElementById('main-cart-footer').dataset.id,
        selector: '.js-contents',
      },
    ];
  }

  updateQuantity(line, quantity, name) {
    this.enableLoading(line);

    const body = JSON.stringify({
      line,
      quantity,
      sections: this.getSectionsToRender().map((section) => section.section),
      sections_url: window.location.pathname,
    });

      fetch(`${routes.cart_change_url}`, { ...fetchConfig(), ...{ body } })
      .then((response) => {
            return response.text();
      })
      .then((state) => {
        const parsedState = JSON.parse(state);
        const quantityElement =
          document.getElementById(`Quantity-${line}`) || document.getElementById(`Drawer-quantity-${line}`);
        const items = document.querySelectorAll('.cart-item');

        if (parsedState.errors) {
          quantityElement.value = quantityElement.getAttribute('value');
          this.updateLiveRegions(line, parsedState.errors);
          return;
        }

        this.classList.toggle('is-empty', parsedState.item_count === 0);
        const cartDrawerWrapper = document.querySelector('cart-drawer');
        const cartFooter = document.getElementById('main-cart-footer');
        const cartCount = document.querySelectorAll('.cart__count');

        cartCount.forEach((element) => {
          element.innerHTML = parsedState.item_count;
        })

        if (cartFooter) cartFooter.classList.toggle('is-empty', parsedState.item_count === 0);
        if (cartDrawerWrapper) cartDrawerWrapper.classList.toggle('is-empty', parsedState.item_count === 0);


        this.getSectionsToRender().forEach((section) => {
          const elementToReplace =
            document.getElementById(section.id).querySelector(section.selector) || document.getElementById(section.id);
          elementToReplace.innerHTML = this.getSectionInnerHTML(
            parsedState.sections[section.section],
            section.selector
          );
        });
        const updatedValue = parsedState.items[line - 1] ? parsedState.items[line - 1].quantity : undefined;
        let message = '';
        if (items.length === parsedState.items.length && updatedValue !== parseInt(quantityElement.value)) {
          if (typeof updatedValue === 'undefined') {
            message = window.cartStrings.error;
          } else {
            message = window.cartStrings.quantityError.replace('[quantity]', updatedValue);
          }
        }
        this.updateLiveRegions(line, message);

        const lineItem =
          document.getElementById(`CartItem-${line}`) || document.getElementById(`CartDrawer-Item-${line}`);
        if (lineItem && lineItem.querySelector(`[name="${name}"]`)) {
          cartDrawerWrapper
            ? trapFocus(cartDrawerWrapper, lineItem.querySelector(`[name="${name}"]`))
            : lineItem.querySelector(`[name="${name}"]`).focus();
        } else if (parsedState.item_count === 0 && cartDrawerWrapper) {
          trapFocus(cartDrawerWrapper.querySelector('.drawer__inner-empty'), cartDrawerWrapper.querySelector('a'));
        } else if (document.querySelector('.cart-item') && cartDrawerWrapper) {
          trapFocus(cartDrawerWrapper, document.querySelector('.cart-item__name'));
        }
        publish(PUB_SUB_EVENTS.cartUpdate, { source: 'cart-items' });
      })
      .catch((error) => {
        this.querySelectorAll('.loading-overlay').forEach((overlay) => overlay.classList.add('hidden'));
        const errors = document.getElementById('cart-errors') || document.getElementById('CartDrawer-CartErrors');
        const errorMessage = error.message ? error.message : window.cartStrings.error;
        errors.textContent = errorMessage;
      })
      .finally(() => {
       nostojs(api => api.loadRecommendations())
        this.disableLoading(line);
      });
  }

  removeMulti(lines) {
      //for each line item format the json

      let updates = {}
      lines.forEach(line => {
          updates[line] = 0;
      });

      fetch(window.Shopify.routes.root + 'cart/update.js', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ updates,
              sections: this.getSectionsToRender().map((section) => section.section),
              sections_url: window.location.pathname, })
        })
        .then(response => {
          return response.json();
        }).then((state) => {
          const parsedState = state;
          const items = document.querySelectorAll('.cart-item');

          if (parsedState.errors) {
            //quantityElement.value = quantityElement.getAttribute('value');
            this.updateLiveRegions(line, parsedState.errors);
            return;
          }

          this.classList.toggle('is-empty', parsedState.item_count === 0);
          const cartDrawerWrapper = document.querySelector('cart-drawer');
          const cartFooter = document.getElementById('main-cart-footer');
          const cartCount = document.querySelectorAll('.cart__count');

          cartCount.forEach((element) => {
            element.innerHTML = parsedState.item_count;
          })

          if (cartFooter) cartFooter.classList.toggle('is-empty', parsedState.item_count === 0);
          if (cartDrawerWrapper) cartDrawerWrapper.classList.toggle('is-empty', parsedState.item_count === 0);


          this.getSectionsToRender().forEach((section) => {
              const elementToReplace =
                document.getElementById(section.id).querySelector(section.selector) || document.getElementById(section.id);
              elementToReplace.innerHTML = this.getSectionInnerHTML(
                parsedState.sections[section.section],
                section.selector
              );
            });
          let message = '';
          //this.updateLiveRegions(line, message);


          publish(PUB_SUB_EVENTS.cartUpdate, { source: 'cart-items' });

        })
        .catch((error) => {
          console.error('Error:', error);
        });
  }

  updateLiveRegions(line, message) {
    const lineItemError =
      document.getElementById(`Line-item-error-${line}`) || document.getElementById(`CartDrawer-LineItemError-${line}`);
    if (lineItemError && message)
    {
      lineItemError.querySelector('.cart-item__error-text').innerHTML = message;
      lineItemError.classList.remove('hidden');
      lineItemError.classList.add('flex');
    }

    this.lineItemStatusElement.setAttribute('aria-hidden', true);

    const cartStatus =
      document.getElementById('cart-live-region-text') || document.getElementById('CartDrawer-LiveRegionText');
    cartStatus.setAttribute('aria-hidden', false);

    setTimeout(() => {
      cartStatus.setAttribute('aria-hidden', true);
    }, 1000);
  }

  getSectionInnerHTML(html, selector) {
    return new DOMParser().parseFromString(html, 'text/html').querySelector(selector).innerHTML;
  }

  enableLoading(line) {
    const mainCartItems = document.getElementById('main-cart-items') || document.getElementById('CartDrawer-CartItems');
    mainCartItems.classList.add('cart__items--disabled');

    const cartItemElements = this.querySelectorAll(`#CartItem-${line} .loading-overlay`);
    const cartDrawerItemElements = this.querySelectorAll(`#CartDrawer-Item-${line} .loading-overlay`);

    [...cartItemElements, ...cartDrawerItemElements].forEach((overlay) => overlay.classList.remove('hidden'));

    document.activeElement.blur();
    this.lineItemStatusElement.setAttribute('aria-hidden', false);
  }

  disableLoading(line) {
    const mainCartItems = document.getElementById('main-cart-items') || document.getElementById('CartDrawer-CartItems');
    mainCartItems.classList.remove('cart__items--disabled');

    const cartItemElements = this.querySelectorAll(`#CartItem-${line} .loading-overlay`);
    const cartDrawerItemElements = this.querySelectorAll(`#CartDrawer-Item-${line} .loading-overlay`);

    cartItemElements.forEach((overlay) => overlay.classList.add('hidden'));
    cartDrawerItemElements.forEach((overlay) => overlay.classList.add('hidden'));
  }
}

customElements.define('cart-items', CartItems);

class CartDrawerItems extends CartItems {
  getSectionsToRender() {
    return [
      {
        id: 'CartDrawer',
        section: 'cart-drawer',
        selector: '.drawer__inner',
      }
    ];
  }
}

customElements.define('cart-drawer-items', CartDrawerItems);

class QuantityInput extends HTMLElement {
  constructor() {
    super();
    this.input = this.querySelector('input');
    this.changeEvent = new Event('change', { bubbles: true });

    this.input.addEventListener('change', this.onInputChange.bind(this));
    this.querySelectorAll('button').forEach((button) =>
      button.addEventListener('click', this.onButtonClick.bind(this))
    );
  }

  quantityUpdateUnsubscriber = undefined;

  connectedCallback() {
    this.validateQtyRules();
    this.quantityUpdateUnsubscriber = subscribe(PUB_SUB_EVENTS.quantityUpdate, this.validateQtyRules.bind(this));
  }

  disconnectedCallback() {
    if (this.quantityUpdateUnsubscriber) {
      this.quantityUpdateUnsubscriber();
    }
  }

  onInputChange(event) {
    this.validateQtyRules();
  }

  onButtonClick(event) {
    event.preventDefault();
    const previousValue = this.input.value;

    event.target.name === 'plus' ? this.input.stepUp() : this.input.stepDown();
    if (previousValue !== this.input.value) this.input.dispatchEvent(this.changeEvent);
  }

  validateQtyRules() {
    const value = parseInt(this.input.value);
    if (this.input.min) {
      const min = parseInt(this.input.min);
      const buttonMinus = this.querySelector(".quantity__button[name='minus']");
      buttonMinus.classList.toggle('disabled', value <= min);
    }
    if (this.input.max) {
      const max = parseInt(this.input.max);
      const buttonPlus = this.querySelector(".quantity__button[name='plus']");
      buttonPlus.classList.toggle('disabled', value >= max);
    }
  }
}

customElements.define('quantity-input', QuantityInput);

class CartRemoveButton extends HTMLElement {
  constructor() {
    super();

    if (this.dataset.bundle){
      const bundleId = this.dataset.bundle;
      const cartItems = this.closest('cart-items') || this.closest('cart-drawer-items');
      const bundleItems = cartItems.querySelectorAll(`[data-bundle="${bundleId}"]`);
      bundleItems.forEach((item, idx, index) => {
          if (idx !== bundleItems.length - 1) {
             item.classList.add('hidden');
             item.closest('.cart-item').classList.remove('border-b');
          }
      });
  }

    this.addEventListener('click', (event) => {
      event.preventDefault();
      const cartItems = this.closest('cart-items') || this.closest('cart-drawer-items');

      //if cart item is a bundle find all cart items with the same bundle ID, remove the cart items with the same bundle ID
      if (this.dataset.bundle){
          const bundleId = this.dataset.bundle
          const bundleItems = cartItems.querySelectorAll(`[data-bundle="${bundleId}"]`);
          let bundleKeys = []
          bundleItems.forEach(item => {
              bundleKeys.push(item.dataset.key);
              //cartItems.updateQuantity(item.dataset.key, 0);
          })
          cartItems.removeMulti(bundleKeys);
      } else {
          cartItems.updateQuantity(this.dataset.index, 0);
      }

    });
  }
}

customElements.define('cart-remove-button', CartRemoveButton);
